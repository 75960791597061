<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <a-btn-save @click="post()" />
      <a-btn-refresh @click="fitchData()" />

      <v-btn color="primary" @click="createNew()" title="Создать элемент">
        <btn-title icon="far fa-plus-square">Создать</btn-title>
      </v-btn>
    </template>
    <TreeBlock @change="onChange($event)" @remove="onRemove($event)" @create="onCreate($event)" :value="data" />
    <portal to="v-main"> </portal>
  </static-fullscreen-card>
</template>

<script>
import { models } from "@/config/models";
export default {
  components: {
    TreeBlock: () => import("@/views/tree/treeBlock"),
  },
  data() {
    return {
      title: "",

      url: "/config",
      defaults: {},
      data: {},
    };
  },
  created() {
    this.$root.title = "Настройки";
    this.fitchData();
  },
  watch: {},
  methods: {
    async fitchData() {
      const resp = await this.$axios.get(this.url);
      if (!(resp.data.status == "ok")) {
        this.$root.$emit("show-info", {
          text: "Ошибка....",
        });
        return;
      }

      //this.data = JSON.parse(resp.data);
      this.data = resp.data.config;
      // this.data.models["taskUserConfigGoods"] = taskUserConfig;
      //console.log(this.data.models["goods"]);
      // this.data.models["cron"] = this.cron;
      // this.data.models["events"] = this.events;
      //      this.data.models["finance"] = this.finance;
      if (false)
        this.data.models["objectPlanning"] = {
          list: [
            { name: "id", title: "#", type: "id", sortable: true, width: 50 },
            {
              name: "name",
              sortable: true,
              style: "white-space: normal;",
              title: "Название",
              type: "string",
              width: 200,
              access: {
                role: [-1],
              },
            },
            { name: "investor_id", title: "Инвестор", type: "select", dir: "investor", style: "white-space: normal;", sortable: true },
            { name: "data.donor_id", title: "Собственник", type: "select", style: "white-space: normal;", dir: "investor" },
            {
              name: "data.is_procuratory",
              title: "Доверенность",
              type: "switcher",
              width: 70,
              access: {
                role: [-1, -20],
              },
            },
            {
              name: "data.is_agreement_investor",
              title: "Договор с инвестором",
              type: "switcher",
              width: 70,
              access: {
                role: [-1, -20],
              },
            },
            {
              name: "data.is_agreement_donor",
              title: "Договор с собственником",
              type: "switcher",
              width: 70,
              access: {
                role: [-1, -20],
              },
            },
            { name: "percent_finish", title: "% вып", type: "string", value: "??", width: 90 },
            { name: "plan_sell_price", title: "Стоимость продажи план", type: "number", sortable: true },
            { name: "ap_price_buy", title: "Стоимость покупки", type: "number" },
            { name: "data.doc_buy_price", title: "Покупка по ДКП", type: "number", width: 80 },
            { type: "number", title: "Продажа по ДКП", name: "data.doc_sell_price", width: 80 },
            {
              name: "tax_value",
              title: "Налог",
              type: "number",
              readonly: true,
              calculated: "true",
              formula: "((d) => {return  (d.data.doc_sell_price && d.data.doc_buy_price) ? ((d.data.doc_sell_price - d.data.doc_buy_price) || null )*0.13 : 0 ;})",
            },
            { name: "plan_expenses", title: "Стоим. реализации план", type: "number" },
            { name: "fact_expenses", title: "Стоим. реализации факт", type: "number", formula: "((d) => {return d.balanceCalc?.type_1 || null;})" },
            { name: "dept_expenses", title: "Неоплаченые заказы", type: "number", formula: "((d) => {return d.balanceCalc?.['type_-1'] || null;})" },
            {
              name: "credit_sum",
              title: "Сумма ипотеки план",
              type: "number",
              access: {
                role: [-1, -20],
              },
            },
            {
              name: "credit_first_payment",
              title: "Перв. взнос план",
              type: "number",
              access: {
                role: [-1, -20],
              },
            },
            {
              name: "fact_first_payment",
              title: "Перв.взнос факт",
              type: "number",
              width: 80,
              readonly: true,
              calculated: true,
              formula: "((d) => { return d.balanceCalc?.type_100 || null;})",
            },
            {
              name: "first_payment_by",
              title: "Перв.взнос источник",
              type: "number",
              width: "80",
              formula: "((d) => { return d.balanceCalc?.type_22 ? 'Компания' : d.balanceCalc?.type_24 ? 'Инвестор' : null;})",
            },
            { name: "data.investment_amount", title: "Вложения инвестора план", type: "number" },
            { name: "fact_investment_amount", title: "Вложения инвестора факт", type: "number", formula: "((d) => {return d.balanceCalc?.['type_2'] || null;})" },
            { name: "plan_month_expenses", title: "Ежемесячные расходы", type: "number" },
            {
              name: "plan_org_expenses",
              title: "Оформление план",
              type: "number",
              access: {
                role: [-1, -20],
              },
            },
            { name: "fact_org_expenses", title: "Оформление факт", type: "number", formula: "((d) => {return d.balanceCalc?.['type_3'] || null;})" },
            { name: "loan_percent6", title: "% по ипотеке 6Мес.", type: "number" },
            {
              name: "plan_money6",
              title: "Плановые вложения 6Мес",
              type: "number",
              readonly: true,
              calculated: true,
              formula: "((d) => {return d.plan_expenses + d.loan_percent6 + d.plan_org_expenses + d.credit_first_payment + d.plan_month_expenses*6;})",
            },
            {
              name: "plan_profit6",
              title: "Прибыль 6Мес",
              type: "number",
              readonly: true,
              calculated: true,
              formula: "( (d) => {return d.plan_sell_price - d.ap_price_buy - d.plan_org_expenses - d.plan_expenses - d.loan_percent6 -d.plan_month_expenses*6;})",
            },
            {
              name: "plan_profit6inv",
              title: "Прибыль инвестора 6Мес ",
              type: "number",
              readonly: true,
              calculated: true,
              formula: "( (d) => {return (d.plan_profit6 * (d.data.investor_share || 0)) / 100;})",
            },
            {
              formula: "((d) => {return (d.plan_profit6 * (100 - (d.data.investor_share || 0))) / 100;})",
              name: "plan_profit6own",
              title: "Прибыль компании 6Мес ",
              type: "number",
              access: {
                role: [-1, -20],
              },
            },
            { name: "profitability6", title: "% доходности 6мес.", type: "number", formula: "((d) => {return (d.plan_profit6inv / d.plan_money6) * 2 * 100;})" },

            { name: "loan_percent12", title: "% по ипотеке 12Мес.", type: "number" },
            {
              name: "plan_money12",
              title: "Плановые вложения 12Мес",
              type: "number",
              readonly: true,
              calculated: true,
              formula: "((d) => {return d.plan_expenses + d.loan_percent12 + d.plan_org_expenses + d.credit_first_payment + d.plan_month_expenses*12 ;})",
            },
            {
              name: "plan_profit12",
              title: "Прибыль 12Мес",
              type: "number",
              readonly: true,
              calculated: true,
              formula: "( (d) => {return d.plan_sell_price - d.ap_price_buy - d.plan_org_expenses - d.plan_expenses - d.loan_percent12 - d.plan_month_expenses*12;})",
            },
            {
              name: "plan_profit12inv",
              title: "Прибыль инвестора 12Мес ",
              type: "number",
              readonly: true,
              calculated: true,
              formula: "( (d) => {return (d.plan_profit12 * (d.data.investor_share || 0)) / 100;})",
            },
            {
              name: "plan_profit12own",
              title: "Прибыль компании 12Мес ",
              type: "number",
              formula: "((d) => {return (d.plan_profit12 * (100 - (d.data.investor_share || 0))) / 100;})",
              access: {
                role: [-1, -20],
              },
            },
            { name: "profitability12", title: "% доходности 12мес.", type: "number", formula: "((d) => {return (d.plan_profit12inv / d.plan_money12) * 2 * 100;})" },

            {
              name: "plan_own_money6",
              title: "Средства ГК (план 6мес)",
              type: "number",
              formula: "((d) => {return d.plan_money6 > parseFloat(d.data.investment_amount) ? d.plan_money6 - parseFloat(d.data.investment_amount) : 0;})",
            },
            { name: "fact_own_money", title: "Средства ГК Мечты (факт)", type: "number", formula: "((d) => {return d.balanceCalc?.type_90 || 0;})" },
          ],
        };
      console.log(this.data);
    },
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    onChange(e) {
      console.log("onChange:", e);
      this.data[e.i] = e.v;
    },
    onCreate(e) {
      console.log("onCreate:", e);
      this.data[e.i] = e.v;

      // this.data[e.i] = e.v;
    },
    onRemove(e) {
      console.log("onRemove:", e);
      this.data[e.i] = e.v;
    },
    async post() {
      //this.data.models = { users: models.users };
      let data = { config: this.data };

      this.loading = true;
      let response = await this.$axios.post(this.url, data);
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Данные записаны",
        });
      }
    },
  },
};
</script>
